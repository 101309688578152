import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'

export default class StatusService {
  $axios: NuxtAxiosInstance
  ownUrl?: string

  constructor (context: Context) {
    this.$axios = context.$axios
    this.ownUrl = location.origin || (<string>import.meta.env.VITE_WEB_APP_URL_DOMAIN)
  }

  public getReleaseInformation () {
    return this.$axios.$get(`${this.ownUrl}/release-info/release.json`)
  }
}
